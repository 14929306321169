import React, {useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser, faBars, faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import {faGithub, faInstagram, faLinkedin, faMedium, faStackOverflow, faTwitter,} from "@fortawesome/free-brands-svg-icons";
import {useNavigate, Link as RouterLink} from "react-router-dom"
import {HashLink} from "react-router-hash-link"
import { Box, Text,HStack, VStack, Image, Link, Switch,useBreakpointValue,IconButton, Button, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerBody, transition} from "@chakra-ui/react";
import {IconButton as IconB} from "@mui/material";
import logo from "../Assets/logo.png" 
import benLogo from "../Assets/Ben-Asenso-Logo.png"
import XIcon from "@mui/icons-material/X"



const socials = [
  {
    icon: faEnvelope,
    url: "mailto: asenso95@gmail.com",
    name: "Mail"
  },
  {
    icon: faGithub,
    url: "https://www.github.com/asenso95",
    name: "Github"
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/in/benasenso",
    name:"LinkedIn"
  },
  {
    icon: faMedium,
    url: "https://www.medium.com/benasenso",
    name: "Medium"
  },
  {
    icon: faStackOverflow,
    url: "https://www.stackoverflow.com/benasenso",
    name: "StackOverflow"
  },
  
  // {
  //   icon: faTwitter,
  //   url: "https://www.twitter.com/ararat-ben",
  //   name: "Twitter"
  // },

  
];


function Header ({toggleProp, setToggle, selected, setSelected}){
  
let navigate = useNavigate()


  //Creating Scrollable view to Projects-Section
function eventHandler(){
  let element = document.getElementById("projects-section")
  if (element){
    element.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }
  else{
    return;
  }
} 
 // Creating a scollable view to HomePage-section
 function eventHandler2 (){
  let element = document.getElementById("homepage-section")
  if(element){
    element.scrollIntoView()
  }
  else{
    return;
  }
 }


 //Creating visibility of the menu depending on Scroll
 let [scrollUp, setscrollUp] = useState(true) 
 let [prevScroll, setprevScroll] = useState(0)

 useEffect((e) => {
  let  handleScroll=() => {
  let verticalScroll = window.scrollY

  setscrollUp(verticalScroll < prevScroll) // This is comparing the previous scroll with the current scroll ie verticalScroll
  setprevScroll(verticalScroll) //this now update the prevSroll(0) with the vertical scroll position number & the comparison continues 


}

  window.addEventListener("scroll", handleScroll)
//Remove Event listener to save Memory when the component is unmounted/Or no longer rendered in the current view/Or user navigates away from page
//The return is a cleanup function for all your cleanup activities in useEffect
return () => {
  window.removeEventListener("scroll", handleScroll)
}
 }, [ prevScroll]) 


 let Hstyle = {

  position: "fixed",
  background: "#18181b",
  color: "white",
  padding: "10px",
  right:"0",
  left:"0",
  top: "0",
  transition: "top 0.3s ease-in-out",
  zIndex: 100
 }


 //Creating a Hamburger Menu / base represents mobile & md represents desktop
 let mobileView = useBreakpointValue({
  base: true,
  lg: false
 })


 let tabletView = useBreakpointValue({
  md: true,
  
 })


 let [isOpen, setIsOpen] = useState(false)

 let onClose = ()=>{
  return setIsOpen(false)
 }

 
 if (mobileView) {
  return (
    <div style={Hstyle}>

      {scrollUp &&
    <HStack spacing={tabletView? 10:8} paddingLeft={tabletView? 4: 1} paddingRight={tabletView? 4: 1} backgroundColor="#18181b" color="white" right="0">
      <Link href="mailto: ben@benasenso.com" isExternal>
      <FontAwesomeIcon icon={faEnvelope} size="2xl"/>
      </Link>
      <Link href="https://www.github.com/asenso95" isExternal>
      <FontAwesomeIcon icon={faGithub} size="2xl" />
       </Link>
         
      <Link href="https://www.linkedin.com/in/benasenso" isExternal>
      <FontAwesomeIcon icon={faLinkedin} size="2xl"/>
      </Link>


      <HStack marginLeft="auto" spacing={8}>
       <HStack spacing={4} >           
          <FontAwesomeIcon size="xl" icon={toggleProp? faSun: faMoon}/>
          <div >
          <Switch size="lg" isChecked={toggleProp} onChange={(e)=> setToggle(!toggleProp)}  />
          </div>
      </HStack>

      <IconButton as={IconB} icon= {<FontAwesomeIcon icon={faBars} size="xl"  />} onClick={(e)=>{
      setIsOpen(true)
    }}/>
    </HStack>
    
       
      
    </HStack>}

    <Drawer 
    isOpen={isOpen}
    onClose={onClose}
    size={ tabletView && "md"}
     >
      <DrawerOverlay>
        <DrawerContent maxH="100vh">
         <DrawerCloseButton color={toggleProp? "white": "black"} size="lg" />
          
         <DrawerHeader bg={toggleProp && "#18181b"} color= "#EDEFEE">
          {/*  <Image src={logo} maxWidth="20vw"  /> */}
            <Link href="https://benasenso.com">
            <Image src={benLogo} alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" boxSize="250px" height={14}  /> 
            </Link>
            
          </DrawerHeader>

          <DrawerBody color={toggleProp && "white"} bg={toggleProp? "#18181b": "linear-gradient(to top, #004e8c, white)"} pt={tabletView && 10}>
            <VStack>
            <Button variant="ghost" transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={toggleProp && "white"}  style={{ background: selected == "Home"? "#2a4365" : "", color: selected == "Home"? "white" :""}} onClick={(e)=> {
               
               navigate("/")
               setSelected("Home")

               setTimeout((e) => {
                 let element = document.getElementById("home")
                 if (element){
                   element.scrollIntoView({
                     behaviour: "smooth"
                   })
                 }
               },5);

            }}>Home</Button>                
            <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={toggleProp && "white"}  style={{ background: selected == "Projects"? "#2a4365" : "", color: selected == "Projects"? "white" :""}} onClick={(e)=> {

              navigate("/")
              setSelected("Projects")

              setTimeout((e) => {
                let element = document.getElementById("projects")
                if (element){
                  element.scrollIntoView({
                    behaviour: "smooth"
                  })
                }
              },5);
            }}>Projects</Button>
           <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={toggleProp && "white"}  style={{ background: selected == "Services"? "#2a4365" : "", color: selected == "Services"? "white" :""}} onClick={(e)=>{
              
              navigate("/")
              setSelected("Services")

              setTimeout((e) => {
                let element = document.getElementById("services")
                if (element){
                  element.scrollIntoView({
                    behaviour: "smooth"
                  })
                }
              },5);

            }}>Services</Button>
             <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={toggleProp && "white"}  style={{ background: selected == "Skills"? "#2a4365" : "", color: selected == "Skills"? "white" :""}} onClick={(e)=>{
              
              navigate("/")
              setSelected("Skills")

              setTimeout((e) => {
                let element = document.getElementById("skills")
                if (element){
                  element.scrollIntoView({
                    behaviour: "smooth"
                  })
                }
              },5);

            }}>Experience</Button>

            <Button as={RouterLink} to="/blog" variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={toggleProp && "white"}  style={{ background: selected == "Blog"? "#2a4365" : "", color: selected == "Blog"? "white" :""}} onClick={(e)=> setSelected("Blog")} > 
            Blog
            </Button> 
            
            <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={toggleProp && "white"}  style={{ background: selected == "Contact" ? "#2a4365" : "", color: selected == "Contact"? "white" :""}} onClick={(e)=>{
            
              navigate("/")
              setSelected("Contact")
              
              setTimeout((e) => {
                let element = document.getElementById("contact")
                if (element){
                  element.scrollIntoView({
                    behaviour: "smooth"
                  })
                }
              },5);
             
            }}
             
            >Contact Me</Button>

            

           
            <HStack spacing={8}  mt={20}>
            <Link href="https://www.stackoverflow.com/benasenso" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt="40">
            <FontAwesomeIcon icon={faStackOverflow} size="2xl" />
            </Link>
            <Link href="https://www.x.com/Ararat_Ben" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt="40">
            <XIcon  style={{fontSize:28}} />
            </Link>
            <Link href="https://www.Instagram.com/bengizzlettl" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt="40">
            <FontAwesomeIcon icon={faInstagram} size="2xl" />
            </Link>
            <Link href="https://www.medium.com/benasenso" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt="40" >
            <FontAwesomeIcon icon={faMedium} size="2xl" />
            </Link>
            
           
            </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    </div>
  )
 }

 else {
  return(
    <div>
      {scrollUp &&
      <HStack spacing={8}  style={Hstyle} >
      
      <Box>
      {socials.map(( f, index) => {
    return <Link key={index} href={f.url} ml={8} isExternal>
          <FontAwesomeIcon icon={f.icon} size="2x" /> 
        </Link>
        
      })}
        <Link  href="https://www.x.com/ararat-ben" ml={8} isExternal>
          <XIcon  style={{fontSize:28, marginTop:-10}}   /> 
        </Link>
      </Box>
      

      <Box marginLeft="auto" marginRight="10">
      <HStack spacing={8} alignSelf="right">

      <HStack spacing={4}>
           
        <FontAwesomeIcon size="xl" icon={toggleProp? faSun: faMoon}/>
         <div >
         <Switch size="lg" isChecked={toggleProp} onChange={(e)=> setToggle(!toggleProp)}  />
         </div>
      </HStack>


      <Link as={HashLink} smooth to="/#home" _hover={{fontWeight: "bold", textDecoration: "underline"}} >
      Home
      </Link>

      <Link as={HashLink} smooth to="/#projects" _hover={{fontWeight: "bold", textDecoration: "underline"}} >
      Projects
      </Link>

      <Link as={HashLink} smooth to="/#services" _hover={{fontWeight: "bold", textDecoration: "underline"}}> 
      Services
      </Link>

      <Link as={HashLink} smooth to="/#skills"  _hover={{fontWeight: "bold", textDecoration: "underline"}}>
      Experience
      </Link>

      <Link as={RouterLink} to="blog" _hover={{fontWeight: "bold", textDecoration: "underline"}}>
      Blog
      </Link>


      <Link as={HashLink} smooth to="/#contact"  _hover={{fontWeight: "bold", textDecoration: "underline"}}>
      Contact Me
      </Link>

     
      </HStack>
      </Box>

      </HStack>}
  </div>
  )
 }

} 
  
export default Header 
 