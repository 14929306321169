import {Box, Text, Center,Heading, HStack, VStack} from "@chakra-ui/react"
import { useBreakpointValue } from "@chakra-ui/react"


function PrivacyPolicy({toggleProp}){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })


  // This is to target md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  if (mobileView){
    return(
          <div>
            
            <Box p={5} minHeight="40vh" backgroundColor={toggleProp? "#18181b":"#2A4365"} paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={1} marginRight={1}  >

                  <Center mt={5} >
                  <h4 style={{margin: 5, paddingTop: 80, fontWeight:"bold", color: "#F4CE14", fontSize: "large"}}>Current as of Oct 12, 2022</h4>
                  </Center>
                      
                  <Heading as= "h1" size="2xl" color="white" id="privacyPolicy">
                          Privacy Policy
                   </Heading><br/>

               
                <Box mt={-10}>
                    <Text fontWeight="bold" color="white" fontSize="xl" >
                    Your privacy is important to us and we are committed to protecting your personal information. This Privacy Policy
                    outlines how we collect, use and safeguard your information when you visit our website.
                    </Text>

                </Box>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} minHeight="40vh" paddingBottom={20}  backgroundColor={toggleProp && "#18181b"} color={toggleProp && "white"} >

              <VStack spacing={5} alignItems="start" marginLeft={1} marginRight={1}>
                  
                <Box>

                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl"  mt={-30}>
                    Collection of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="large" >
                    We only collect personal information that is necessary for you to access and use our services. 
                    This may include but is not limited to your name, email address, and any other information you 
                    voluntarily provide to us. <br/><br/>
                    </Text>
 
                    
                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Use of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="large" >
                    We do not share, sell, rent, or provide outside access to your personal information to any third party. 
                    Any data that you provide to us is solely owned by you or your business. We may use your personal information 
                    to communicate with you, provide support, improve our services and for other internal purposes. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Disclosure of Personal Information
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    We may disclose your personal information if required by law, search warrant, subpoena, court order, 
                    or fraud investigation. Additionally, we may use aggregated and anonymized data for statistical purposes, 
                    such as informing third parties about the number of visitors to our website. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Data Security
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    We take appropriate measures to protect your personal information from unauthorized access, alteration, 
                    disclosure, or destruction. However, please be aware that no method of transmission over the internet or 
                    electronic storage is 100% secure and we cannot guarantee absolute security. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Changes to Privacy Policy
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    If we make any changes to our Privacy Policy, we will post those changes on this page. Registered users will also 
                    be notified via email about any significant updates. By continuing to use our website after such changes, you agree 
                    to the revised Privacy Policy. <br/><br/>
                    </Text>


                    <Box mt={8} >
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Contact Us
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    If you have any questions or concerns about our Privacy Policy, please contact 
                    us at ben@benasenso.com.<br/><br/>
                    By using our website, you consent to the terms of this Privacy Policy.
                    </Text>

                </Box> 
               
      
              </VStack>

                   
            </Box>        

          </div>
    )
  }

  else{

    return(
        <div>

            <Box p={5} minHeight="40vh" backgroundColor={toggleProp? "#18181b":"#2A4365"} paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20} marginRight={30} paddingRight={tabletView? 0:20}>

                  <Center mt={5}  >
                  <h4 style={{margin: 5, paddingTop: 80, fontWeight:"bold", color: "#F4CE14"}}>Current as of Oct 12, 2022</h4>
                  </Center>
 
                      
                  <Heading as= "h1" size="3xl" color="white"  >
                          Privacy Policy
                   </Heading><br/>

            
                <Box mt={-4} >
                    <Text fontWeight="bold" color="white" fontSize="xl" >
                    Your privacy is important to us and we are committed to protecting your personal information. This Privacy Policy
                    outlines how we collect, use and safeguard your information when you visit our website.
                    </Text>

                </Box>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} pt={10} minHeight="40vh" paddingBottom={20}  backgroundColor={toggleProp && "#18181b"} color={toggleProp && "white"} >

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20}  marginRight={30} paddingRight={tabletView? 0:20}>
                  
               
                <Box>

                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Collection of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="xl" >
                    We only collect personal information that is necessary for you to access and use our services. 
                    This may include but is not limited to your name, email address, and any other information you 
                    voluntarily provide to us. <br/><br/>
                    </Text>
 
                    
                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Use of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="xl" >
                    We do not share, sell, rent, or provide outside access to your personal information to any third party. 
                    Any data that you provide to us is solely owned by you or your business. We may use your personal information 
                    to communicate with you, provide support, improve our services and for other internal purposes. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Disclosure of Personal Information
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    We may disclose your personal information if required by law, search warrant, subpoena, court order, 
                    or fraud investigation. Additionally, we may use aggregated and anonymized data for statistical purposes, 
                    such as informing third parties about the number of visitors to our website. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Data Security
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    We take appropriate measures to protect your personal information from unauthorized access, alteration, 
                    disclosure, or destruction. However, please be aware that no method of transmission over the internet or 
                    electronic storage is 100% secure and we cannot guarantee absolute security. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Changes to Privacy Policy
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    If we make any changes to our Privacy Policy, we will post those changes on this page. Registered users will also 
                    be notified via email about any significant updates. By continuing to use our website after such changes, you agree 
                    to the revised Privacy Policy. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Contact Us
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    If you have any questions or concerns about our Privacy Policy, please contact 
                    us at ben@benasenso.com.<br/><br/>
                    By using our website, you consent to the terms of this Privacy Policy.
                    </Text>

                </Box>
      
              </VStack>

                   
            </Box>

        </div>
    )
  }
}

export default PrivacyPolicy