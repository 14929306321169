import React, {useState, useEffect} from "react";
//import FullScreenSection from "./FullScreenSection";
import { Box, Heading, Highlight, LinkBox, LinkOverlay, useBreakpointValue} from "@chakra-ui/react";

import {VStack, HStack, Card, Image, Text, Collapse, Link} from "@chakra-ui/react"
import photo1 from "../Assets/photo1.jpg"
import photo2 from "../Assets/photo2.jpg"
import photo3 from "../Assets/photo3.jpg"
import photo4 from "../Assets/photox.jpg"
import lemonS from "../Assets/lemonS.png"
import pexels from "../Assets/pexels.jpg"
import netflix from "../Assets/netflix.png"


import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from "react-intersection-observer"
import {v4 as uuidv4} from 'uuid'




const projects = [
 {
  title: "Little lemon",
  description: " a family owned Mediterranean restaurant focused on traditional recipes served with a modern twist.",
  getImageSrc: lemonS,
  paragraph: "  A Mediterranean restaurant focused on traditional recipes served with a twist. At Little Lemon, we pride ourselves on being more than just a dining destination; we're a family-oriented Mediterranean haven where traditions come to life with a contemporary flair.",
  uuid: uuidv4(),
  number: 5
},
  
  {
    title: "React Space",
    description: "Handy tool belt to create amazing AR components in a React app, with redux integration via middleware️.",
    getImageSrc: photo1,
    paragraph: "Whether you're building an immersive vitual tour, an interactive e-commerce experience, or a gamified learning plaform. React Space provides you with an extensive set of pre-built AR components, APIs, and tools to expedite your development process.",
    uuid: uuidv4(),
    number: 1
  },

  {
    title: "React Infinite Scroll",
    description: "A scrollable bottom sheet with virtualisation support, native animations at 60 FPS and fully implemented in JS land 🔥️.",
    getImageSrc: photo2,
    paragraph: "Introduces a unique bottom sheet design that allows you to present contentin an engaging and interactive manner. Users can effortlessly pull up the content from the bottom of the screen, adding a touch of innovation to your web app. Tailor the scrollable bottom sheet to match your application's unique style and branding.",
    uuid: uuidv4(),
    number: 2
  },

  {
    title: "Photo Gallery",
    description: "A One-stop shop for photographers to share and monetize their photos, allowing them to have a second source of income.",
    getImageSrc: photo3,
    paragraph: "With a an elegant and user-friendly interface, photographers can effortlessly upload and organize their high-quality photos, creating stunning galleries that captivate viewers and potential clients. The ultimate platform for photographers looking to exhibit their creativity and turn their passion into a thriving business.",
    uuid: uuidv4(),
    number: 3
  },

  {
    title: "Event planner",
    description: "A mobile application for leisure seekers to discover unique events and activities in their city with a few taps.",
    getImageSrc: photo4,
    paragraph: "Designed to cater to leisure seekers, providing them with an effortless way to diswcover and engage with unique events and activities in their city. Leverages advanced algorithms to understand user's preferences and interests. It tailors event recommendations based on individual tastes, ensuring a truly personalized experience.",
    uuid: uuidv4(),
    number: 4
  },
];



function ProjectsSection({toggleProp, setToggle, setSelected}){
  
  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })

  
  
  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  let [isCollapsed, setIsCollapsed] = useState(true)
  let [isCollapsed2, setIsCollapsed2] = useState(true)
  let [isCollapsed3, setIsCollapsed3] = useState(true)
  let [isCollapsed4, setIsCollapsed4] = useState(true)
  let [isCollapsed5, setIsCollapsed5] = useState(true)
  let [isCollapsed6, setIsCollapsed6] = useState(true)

  let [overlay, setOverlay] = useState(false)
  let [overlay2, setOverlay2] = useState(false)
  let [overlay3, setOverlay3] = useState(false)

  let {ref: refProjects, inView: inViewProjects} = useInView({triggerOnce: false})

  useEffect((e)=>{
    
    if(inViewProjects){
      setSelected("Projects")
    }
  }, [inViewProjects])




  
 
  return(
    <div >
      <VStack spacing={8} padding={10} alignItems="left" background={toggleProp? "#18181b":"#14532d"} >  
          <Heading as="h2" size="2xl" color="white" id="projects" ref={refProjects}>Featured Projects</Heading>
        
        <Box display="grid" ml={mobileView? -5: (tabletView? -10: "")} mr={mobileView? -5: (tabletView? -10: "")} paddingLeft={mobileView? 0:10} paddingRight={mobileView? 0:10} gridTemplateColumns= {mobileView? "1fr": "1fr 1fr"} gap={8} >
         
          <Card borderRadius={10} cursor="pointer" _hover={{transform: "scale(1.05)", boxShadow: "0px 0px 50px black", transition: "transform 0.3s ease-in-out"}} bg={toggleProp && "#18181b"} color={toggleProp && "white"} >
              
              <Box  onMouseDown={(e)=> setOverlay(true)} onMouseUp={(e)=> setOverlay(false)} onMouseLeave={(e)=> setOverlay(false)} onMouseEnter={(e)=>{
              if(tabletView || mobileView) {
                setOverlay(true)
              }
              }}
              >
             
               {/* Creating Overlay */}
        {overlay && <div style = {{
                background: "rgba(34,167,240,0.5)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>
            }
              <Link href="https://app.benasenso.com"  isExternal>      
              <Image src={lemonS} loading="lazy" borderRadius={10} height={427} objectFit="cover"/>              
              </Link>
                     
              </Box>
      
              
                  
              <Box p={8} >
              <Heading as="h3" size="md">Little Lemon</Heading>
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              A family owned Mediterranean restaurant focused on traditional recipes served with a modern twist.
              </Text>
              
              <Collapse in={!isCollapsed}>
              <Text fontSize="lg" color={toggleProp? "white":"#64748b"}>
                A Mediterranean restaurant focused on traditional recipes served with a twist. At Little Lemon, we pride ourselves on being more than just a dining destination; we're a family-oriented Mediterranean haven where traditions come to life with a contemporary flair.
              </Text>
              </Collapse>

              <HStack mt={-4} spacing={2}>
              <Text mt={4} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} onClick={(e) => {
                setIsCollapsed(!isCollapsed)
              }}>{isCollapsed? "See More":"See Less"} </Text>
              
             {isCollapsed? <FontAwesomeIcon icon={faArrowRight}onClick={(e) => {
                setIsCollapsed(!isCollapsed)
              }}></FontAwesomeIcon> : <FontAwesomeIcon icon={ faArrowLeft} onClick={(e) => {
                setIsCollapsed(!isCollapsed)
              }}></FontAwesomeIcon>}
              </HStack>
              </Box>
            </Card>
    
            
          
             <Card borderRadius={10} cursor="pointer"  _hover={{transform: "scale(1.05)", boxShadow: "0px 0px 50px black", transition: "transform 0.3s ease-in-out"}} color={toggleProp && "white"} bg={toggleProp && "#18181b" } >
          
             <Box  onMouseDown={(e)=> setOverlay3(true)} onMouseUp={(e)=> setOverlay3(false)} onMouseLeave={(e)=> setOverlay3(false)} onMouseEnter={(e)=>{
              if(tabletView || mobileView) {
                setOverlay3(true)
              }
              }}
              >
             
               {/* Creating Overlay */}
        {overlay3 && <div style = {{
                background: "rgba(34,167,240,0.5)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>
            }
              <Link href="https://stagingnfx.web.app"  isExternal>            
              <Image src={netflix} loading="lazy" borderRadius={10} height={427} objectFit="cover" />      
              </Link>
                     
              </Box>
      
              
              <Box p={8} >
              <Heading as="h3" size="md"> Netflix</Heading>
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              Netflix offers users a seamless experience for browsing and streaming movies and tv shows.
              </Text>
              
              <Collapse in={!isCollapsed2}>
              <Text fontSize="lg" color={toggleProp? "white": "#64748b"}>
              Designed for an optimal user experience, it allows members to browse through genres, discover trending titles, and view detailed descriptions and reviews for each show.
              Users can enjoy a smooth interface for watching content on-demand, all while getting tailored recommendations based on their viewing habits.
              </Text>
              </Collapse>

              <HStack mt={-4} spacing={2}>
              <Text mt={4} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} onClick={(e) => {
                setIsCollapsed2(!isCollapsed2)
              }}>{isCollapsed2? "See More":"See Less"} </Text>
              
             {isCollapsed2? <FontAwesomeIcon icon={faArrowRight}onClick={(e) => {
                setIsCollapsed2(!isCollapsed2)
              }}></FontAwesomeIcon> : <FontAwesomeIcon icon={ faArrowLeft} onClick={(e) => {
                setIsCollapsed2(!isCollapsed2)
              }}></FontAwesomeIcon>}
              </HStack>
              </Box>
            </Card>
          


            <Card borderRadius={10} cursor="pointer"  _hover={{transform: "scale(1.05)", boxShadow: "0px 0px 50px black", transition: "transform 0.3s ease-in-out"}}  bg={toggleProp && "#18181b"} color={toggleProp && "white"}>
              <Image src={photo2} loading="lazy" borderRadius={10} height={427} objectFit="cover" />
              
              <Box p={8} >
              <Heading as="h3" size="md">React Infinite Scroll</Heading>
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              A scrollable bottom sheet with virtualisation support, native animations at 60 FPS and fully implemented in JS land 🔥️.
              </Text>
              
              <Collapse in={!isCollapsed3}>
              <Text fontSize="lg" color={toggleProp? "white": "#64748b"}>
              Introduces a unique bottom sheet design that allows you to present contentin an engaging and interactive manner. Users can effortlessly pull up the content from the bottom of the screen, adding a touch of innovation to your web app. Tailor the scrollable bottom sheet to match your application's unique style and branding.
              </Text>
              </Collapse>

              <HStack mt={-4} spacing={2}>
              <Text mt={4} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} onClick={(e) => {
                setIsCollapsed3(!isCollapsed3)
              }}>{isCollapsed3? "See More":"See Less"} </Text>
              
             {isCollapsed3? <FontAwesomeIcon icon={faArrowRight}onClick={(e) => {
                setIsCollapsed3(!isCollapsed3)
              }}></FontAwesomeIcon> : <FontAwesomeIcon icon={ faArrowLeft} onClick={(e) => {
                setIsCollapsed3(!isCollapsed3)
              }}></FontAwesomeIcon>}
              </HStack>
              </Box>
            </Card>


            <Card borderRadius={10} cursor="pointer"  _hover={{transform: "scale(1.05)", boxShadow: "0px 0px 50px black", transition: "transform 0.3s ease-in-out"}} bg={toggleProp && "#18181b"} color={toggleProp && "white"}>
              <Image src={photo3} loading="lazy" borderRadius={10} height={427} objectFit="cover" />
              
              <Box p={8} >
              <Heading as="h3" size="md">Photo Gallery</Heading>
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              A One-stop shop for photographers to share and monetize their photos, allowing them to have a second source of income.
              </Text>
              
              <Collapse in={!isCollapsed4}>
              <Text fontSize="lg" color={toggleProp? "white": "#64748b"}>
              With a an elegant and user-friendly interface, photographers can effortlessly upload and organize their high-quality photos, creating stunning galleries that captivate viewers and potential clients. The ultimate platform for photographers looking to exhibit their creativity and turn their passion into a thriving business.

              </Text>
              </Collapse>

              <HStack mt={-4} spacing={2}>
              <Text mt={4} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} onClick={(e) => {
                setIsCollapsed4(!isCollapsed4)
              }}>{isCollapsed4? "See More":"See Less"} </Text>
              
             {isCollapsed4? <FontAwesomeIcon icon={faArrowRight}onClick={(e) => {
                setIsCollapsed4(!isCollapsed4)
              }}></FontAwesomeIcon> : <FontAwesomeIcon icon={ faArrowLeft} onClick={(e) => {
                setIsCollapsed4(!isCollapsed4)
              }}></FontAwesomeIcon>}
              </HStack>
              </Box>
            </Card>


            <Card borderRadius={10} cursor="pointer"  _hover={{transform: "scale(1.05)", boxShadow: "0px 0px 50px black", transition: "transform 0.3s ease-in-out"}} bg={toggleProp && "#18181b"} color={toggleProp && "white"}>
              <Image src={photo4} loading="lazy" borderRadius={10} height={427} objectFit="cover" />
              
              <Box p={8} >
              <Heading as="h3" size="md">Event Planner</Heading>
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              A mobile application for leisure seekers to discover unique events and activities in their city with a few taps.
              </Text>
              
              <Collapse in={!isCollapsed5}>
              <Text fontSize="lg" color={toggleProp? "white": "#64748b"}>
              Designed to cater to leisure seekers, providing them with an effortless way to diswcover and engage with unique events and activities in their city. Leverages advanced algorithms to understand user's preferences and interests. It tailors event recommendations based on individual tastes, ensuring a truly personalized experience.
              </Text>
              </Collapse>

              <HStack mt={-4} spacing={2}>
              <Text mt={4} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} onClick={(e) => {
                setIsCollapsed5(!isCollapsed5)
              }}>{isCollapsed5? "See More":"See Less"} </Text>
              
             {isCollapsed5? <FontAwesomeIcon icon={faArrowRight}onClick={(e) => {
                setIsCollapsed5(!isCollapsed5)
              }}></FontAwesomeIcon> : <FontAwesomeIcon icon={ faArrowLeft} onClick={(e) => {
                setIsCollapsed5(!isCollapsed5)
              }}></FontAwesomeIcon>}
              </HStack>
              </Box>
            </Card>


            <Card borderRadius={10} cursor="pointer"  _hover={{transform: "scale(1.05)", boxShadow: "0px 0px 50px black", transition: "transform 0.3s ease-in-out"}} bg={toggleProp && "#18181b"} color={toggleProp && "white"}>
              
              <Box  onMouseDown={(e)=> setOverlay2(true)} onMouseUp={(e)=> setOverlay2(false)} onMouseLeave={(e)=> setOverlay2(false)} onMouseEnter={(e)=>{
              if(tabletView || mobileView) {
                setOverlay2(true)
              }
              }}
              >
             
               {/* Creating Overlay */}
        {overlay2 && <div style = {{
                background: "rgba(34,167,240,0.5)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>
            }
              <Link href="https://blogai.benasenso.com"  isExternal>            
              <Image src={pexels} loading="lazy" borderRadius={10} height={427} objectFit="cover" />      
              </Link>
                     
              </Box>
      
              

              <Box p={8} >
              <Heading as="h3" size="md">AI Blog Generator</Heading>
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              A revolutionary that offers a sleek and intuitive interface for users to interact with advanced AI functionalities.
              </Text>
              
              <Collapse in={!isCollapsed6}>
              <Text fontSize="lg" color={toggleProp? "white": "#64748b"}>
              AI Blog Generator offers seamless way to transform video content to comprehensive written articles while ensuring high-quality, engaging content.  
              With its cutting-edge AI technology to deliver accurate and coherent blog posts , AI Blog Generator sets a new standard for user-centric design, ushering in a new era of innovation in the digital landscape.
              </Text>
              </Collapse>

              <HStack mt={-4} spacing={2}>
              <Text mt={4} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} onClick={(e) => {
                setIsCollapsed6(!isCollapsed6)
              }}>{isCollapsed6? "See More":"See Less"} </Text>
              
             {isCollapsed6? <FontAwesomeIcon icon={faArrowRight}onClick={(e) => {
                setIsCollapsed6(!isCollapsed6)
              }}></FontAwesomeIcon> : <FontAwesomeIcon icon={ faArrowLeft} onClick={(e) => {
                setIsCollapsed6(!isCollapsed6)
              }}></FontAwesomeIcon>}
              </HStack>
              </Box>
            </Card>
           
          
        </Box>
          
            
        
      </VStack>
    </div>
  )
  }



export default ProjectsSection;
